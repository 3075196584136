import { SOURCES_OF_INCOME_WITH_REQUIRED_DOCUMENT } from '~/constants';
import {
  AmlSourceOfIncome,
  type BankAccount,
  type Client,
  OCRResult,
  RegistrationStatus,
  BankIdState,
} from '~/models/clients';
import { ClientDocumentType, type ClientDocument } from '~/models/documents';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) {
    return;
  }

  const {
    // using $localePath instead of useLocalePath will not result in warning:
    // Calling useRoute within middleware may lead to misleading results
    $localePath: localePath,
  } = useNuxtApp();
  const authStore = useAuthStore();
  const documentsStore = useDocumentsStore();
  const clientsStore = useClientStore();

  const fromName = from.name as string | null | undefined;
  if (!to.redirectedFrom && !fromName?.includes('external-') && !fromName?.includes('account-')) {
    if (
      authStore.user.RegistrationStatus === RegistrationStatus.InProgress ||
      authStore.user.RegistrationStatus === RegistrationStatus.ClientResponseNeeded
    ) {
      if (shouldRedirectToPersonalData(authStore.user)) {
        return await navigateTo(localePath('account-personal-data'));
      }

      if (shouldRedirectToPhoneVerification(authStore.user)) {
        return await navigateTo(localePath('account-phone-number-verification'));
      }

      await documentsStore.getClientDocumentsStatus({ force: true });
      if (shouldRedirectToIdentityVertification(documentsStore.clientDocuments, authStore.user)) {
        return await navigateTo(localePath('account-identity-verification'));
      }

      if (
        shouldRedirectToIdentityVertificationDocumentsUpload(
          documentsStore.clientDocuments,
          authStore.user,
        )
      ) {
        return await navigateTo(localePath('account-identity-verification-upload-documents'));
      }

      await clientsStore.getBankAccounts({});
      if (shouldRedirectToBankAccount(clientsStore.bankAccounts, documentsStore.clientDocuments)) {
        return await navigateTo(localePath('account-bank-account'));
      }

      if (shouldRedirectToAdditionalInformation(authStore.user)) {
        return await navigateTo(localePath('account-additional-information'));
      }

      if (shouldRedirectToSourdeOfIncomeStatement(authStore.user, documentsStore.clientDocuments)) {
        return await navigateTo(localePath('account-source-of-income-statement'));
      }

      if (
        shouldRedirecToPoliticallyExposedPerson(authStore.user, documentsStore.clientDocuments) &&
        !shouldRedirecToDocumentsCheck(authStore.user)
      ) {
        return await navigateTo(localePath('account-politically-exposed-person'));
      }

      if (shouldRedirecToDocumentsCheck(authStore.user)) {
        return await navigateTo(localePath('account-documents-check'));
      }

      if (shouldRedirectToAddresses(authStore.user)) {
        return await navigateTo(localePath('account-addresses'));
      }

      if (shouldRedirectToVerification(authStore.user)) {
        return await navigateTo(localePath('account-verification'));
      }
    }
  }
});

function shouldRedirectToPersonalData(user: Client) {
  return (
    (user.FirstName ?? '').trim().length === 0 ||
    (user.LastName ?? '').trim().length === 0 ||
    (user.Nationality ?? '').trim().length === 0 ||
    (user.IdentityNumber ?? '').trim().length === 0 ||
    (user.Phone ?? '').trim().length === 0
  );
}

function shouldRedirectToPhoneVerification(user: Client) {
  return !user.IsPhoneVerified;
}

function shouldRedirectToIdentityVertification(documents: ClientDocument[], user: Client) {
  return (
    !(
      documents.some((document) => document.Name === 'id') ||
      documents.some((document) => document.Name === 'id-back') ||
      documents.some((document) => document.Name === 'id-other')
    ) &&
    (!user.BankIdState || user.BankIdState !== BankIdState.Success)
  );
}

function shouldRedirectToIdentityVertificationDocumentsUpload(
  documents: ClientDocument[],
  user: Client,
) {
  return (
    (!documents.some((document) => document.Name === 'id') ||
      !documents.some((document) => document.Name === 'id-back') ||
      !documents.some((document) => document.Name === 'id-other')) &&
    (!user.BankIdState || user.BankIdState !== BankIdState.Success)
  );
}

function shouldRedirectToBankAccount(bankAccounts: BankAccount[], documents: ClientDocument[]) {
  return (
    bankAccounts.length === 0 &&
    !documents.find(
      (document) =>
        document.Name === 'bank-account-statement-header' &&
        (document.IsVerified === null || document.IsVerified === undefined),
    )
  );
}

function shouldRedirectToAdditionalInformation(user: Client) {
  return (
    !user.AmlSourceOfIncome ||
    user.IsNotPep === null ||
    user.IsNotPep === undefined ||
    (user.TaxResidency ?? '').trim().length === 0 ||
    (user.AmlSourceOfIncome === AmlSourceOfIncome.Enterpreneurship && !user.AmlSector) ||
    (user.TaxResidency !== user.Nationality &&
      (user.TaxIdentificationNumber ?? '').trim().length === 0)
  );
}

function shouldRedirectToSourdeOfIncomeStatement(user: Client, documents: ClientDocument[]) {
  return (
    user.AmlSourceOfIncome &&
    SOURCES_OF_INCOME_WITH_REQUIRED_DOCUMENT.includes(user.AmlSourceOfIncome.toString()) &&
    !documents.some((document) => document.Type === ClientDocumentType.SourceOfIncomeStatement)
  );
}

function shouldRedirecToPoliticallyExposedPerson(user: Client, documents: ClientDocument[]) {
  return (
    user.RegistrationStatus === RegistrationStatus.InProgress &&
    !(user.IsNotPep === null || user.IsNotPep === undefined) &&
    !user.IsNotPep &&
    (!documents.some(
      (document) => document.Type === ClientDocumentType.PepSourceOfIncomeStatement,
    ) ||
      (user.PEPReason ?? '').length === 0)
  );
}

function shouldRedirecToDocumentsCheck(user: Client) {
  return (
    user.OCRResult === OCRResult.Success &&
    (user.OCRIdentificationChangedByClient === null ||
      user.OCRIdentificationChangedByClient === undefined)
  );
}

function shouldRedirectToAddresses(user: Client) {
  return (
    (user.ResidencyAddress.Street ?? '').length === 0 ||
    (user.ResidencyAddress.City ?? '').length === 0 ||
    (user.ResidencyAddress.ZipCode ?? '').length === 0 ||
    (user.ResidencyAddress.Country ?? '').length === 0 ||
    (user.DeliveryAddress.Street ?? '').length === 0 ||
    (user.DeliveryAddress.City ?? '').length === 0 ||
    (user.DeliveryAddress.ZipCode ?? '').length === 0 ||
    (user.DeliveryAddress.Country ?? '').length === 0
  );
}

function shouldRedirectToVerification(user: Client) {
  return user.RegistrationStatus === RegistrationStatus.InProgress && !user.IsAgreedWithTerms;
}
